import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import DHeader from "../../components/DashboardHeader/DHeader";
import Country from "../../assets/countries.json";
import editIcon from "../../assets/icons/EditIcon.svg";
import { useNavigate } from "react-router-dom";
import defaultProfileImage from "../../assets/image/CompanyLogo.png";
import "./EditProfile.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function EditProfile() {
  const id = localStorage.getItem("GeneratedKey");
  const fileInputRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [profileImage, setProfileImage] = useState(defaultProfileImage);
  localStorage.setItem("profileImage", profileImage);
  const navigate = useNavigate();
  // const handleEditClick = () => {
  //   // Trigger the hidden file input when edit icon is clicked
  //   document.getElementById("fileInput").click();
  // };
  const [formData, setFormData] = useState({
    fullname: "",
    Email: "",
    Gender: "",
    country: "",
    seedingdate: "",
    phonenumber: "",
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = String(date.getFullYear()); // Get last 2 digits of year
    return `${day}/${month}/${year}`;
  };

  const handleChange = (e, field) => {
    let value = e.target.value;

    if (field === "seedingdate") {
      value = formatDate(value); // Convert to dd-MM-yy format
    }
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // You can handle the uploaded file here (e.g., display it or upload it to a server)
      const imageUrl = URL.createObjectURL(file); // Create a URL for the uploaded image
      // setSelectedImage(imageUrl);
      const formData = new FormData();
      formData.append("image", file);
      formData.append("id", id);

      try {
        // Upload image to the server
        const response = await axios.post(
          "https://soiltech.ai/api/ProfileUpload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data?.imageUrl) {
          setProfileImage(response.data.imageUrl);
        } else {
          setProfileImage("");
        }
      } catch (error) {
        console.error("Error uploading the image", error);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://soiltech.ai/api/login/Update",
        { id: id, formData: formData }
      );
      toast.success("Successfully Updated!", {
        position: "top-right",
        autoClose: 500, // time in milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: "green",
          color: "white",
        },
      });
      // navigate('/dashboard', { state: { updatedImage: profileImage } });
      // localStorage.setItem('profileImage', profileImage);
    } catch (error) {
      toast.error("Submission Failed. Please try again.", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: "red",
          color: "white",
        },
      });
    }
  };
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      // Create a URL for the new uploaded image and set it to profileImage state
      const newImage = URL.createObjectURL(event.target.files[0]);
      console.log("the new image is", newImage);
      setProfileImage(newImage);
      console.log(newImage);
    }
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600); // Mobile if screen width <= 768px
  };

  // Convert 'dd/mm/yyyy' -> 'yyyy-mm-dd' for input field
  const formatDateToInput = (date) => {
    if (!date) return "";

    const [day, month, year] = date.split("/");

    // Ensure two-digit month and day
    const formattedMonth = month.padStart(2, "0");
    const formattedDay = day.padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  useEffect(() => {
    // If there's an initial date, ensure it's in the correct format

    if (formData.seedingdate) {
      const formattedDate = formatDateToInput(formData.seedingdate);
      setFormData((prev) => ({ ...prev, seedingdate: formattedDate }));
    }
  }, []);

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize); // Add event listener for resizing
    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);
  const handleAddFarmClick = () => {
    navigate("/addfarm"); // Navigate to the Add Farm page
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axios.get(
          "https://soiltech.ai/api/getProfileImage",
          {
            params: {
              id: id,
            },
          }
        );
        console.log("ye hai filedata", response.data);
        setProfileImage(response.data.imageUrl); // Save the file path to state
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    };

    fetchProfileImage(); // Fetch profile image on component mount
  }, [id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("https://soiltech.ai/api/login", {
          params: {
            id: id,
          },
        });
        // console.log("shi aa rha hai",response.data)
        const inputData = response.data?.LoginData;
        if (inputData) {
          setFormData({
            fullname: inputData?.Fullname,
            Email: inputData?.Email,
            Gender: inputData?.Gender,
            country: inputData?.country,
            seedingdate: inputData?.seedingdate,
            phonenumber: inputData?.phonenumber,
          });
        }
      } catch (error) {
        console.error("Error fetching soil test details:", error);
      }
    };

    fetchUserDetails();
  }, [id]);
  // console.log('the data is',formData)
  return (
    <div className="EditProfile-Container">
      <div className="EditProfile-Container1">
        <Sidebar />
      </div>
      <div className="EditProfile-Container2">
        <DHeader isMobile={isMobile} />
        <div className="EditProfile-maincontent">
          <div className="EditProfile-maincontent-top">
            <div className="Edit-top-first-half" style={{ width: "45%" }}></div>
            <div className="Edit-top-second-half">
              <div className="EditProfile-Image">
                <div className="avatar-wrapper">
                  <img
                    src={profileImage}
                    alt="Profile Avatar"
                    className="avatar-image"
                  />
                  <div className="edit-badge" onClick={handleClick}>
                    <img src={editIcon} alt="Edit Icon" className="edit-icon" />
                  </div>
                  <p className="profile-text">Edit Profile</p>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              <div className="EditProfile-Button">
                <button className="Edit-Button" onClick={handleAddFarmClick}>
                  + Add a New Farm{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="EditProfile-maincontent-middle">
            <div className="EditProfile-form1">
              <label className="form1-label"> Full Name</label>
              <input
                type="text"
                placeholder="Ram"
                className="EditProfile-form1-input"
                value={formData.fullname}
                onChange={(e) => handleChange(e, "fullname")}
              />

              <label className="form1-label"> Gender</label>
              <select
                type="text"
                name="Gender"
                className="EditProfile-form2-input"
                value={formData.Gender}
                onChange={(e) => handleChange(e, "Gender")}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>

              <label className="form1-label">Email</label>
              <input
                type="Email"
                placeholder="Ram@gmail.com"
                className="EditProfile-form1-input"
                value={formData.Email}
                disabled
                onChange={(e) => handleChange(e, "Email")}
              />
            </div>
            <div className="EditProfile-form2">
              {/* <label className="form1-label"> Last Name</label>
              <input type="text" placeholder="Doe" className="EditProfile-form1-input" value={formData.LastName} onChange={(e) =>handleChange(e,"LastName")} /> */}

              <label className="form1-label">Country</label>
              <select
                name="country"
                className="EditProfile-form2-input"
                value={formData.country}
                onChange={(e) => handleChange(e, "country")}
              >
                <option value="">Select Country</option>
                {Country.countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>

              <label className="form1-label">Seeding Date</label>
              <input
                type="date"
                className="EditProfile-form2-input"
                name="date"
                placeholder="mm/dd/yyyy"
                value={formatDateToInput(formData.seedingdate)}
                onChange={(e) => handleChange(e, "seedingdate")}
              />
              <label className="form1-label">Phone Number</label>
              <input
                type="text"
                placeholder="767***7878"
                className="EditProfile-form1-input"
                value={formData.phonenumber}
                onChange={(e) => handleChange(e, "phonenumber")}
              />
            </div>
          </div>
          <div className="EditProfile-maincontent-bottom">
            <button
              className="EditProfile-update-button"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default EditProfile;
