import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./OTPPage.css";
import axios from "axios";
import backgroundImg from "../../assets/image/farmers-scheme-istock.svg";
import googlelogo from "../../assets/image/google.svg";
import SoilImage from "../../assets/image/SoilTech.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftColumn from "../../components/LeftColumn/LeftColumn";

function OTPPage() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  //   const [formData, setFormData] = useState(null);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [resendMessage, setResendMessage] = useState("");
  const location = useLocation();
  const email = localStorage.getItem("Email");
  console.log("email aaa gay hai", email);
  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });
  };

  const handleSubmitOtp = async () => {
    try {
      const response = await axios.post(
        "https://soiltech.ai/api/login/verify-otp",
        {
          email: email, // Use the email from the form or state
          otp: otp.join(""),
        }
      );
      if (response.status === 200) {
        toast.success("OTP verified successfully!");
        localStorage.removeItem("Email");
        navigate("/signin");
      }
    } catch (error) {
      toast.error("OTP verification failed");
      console.error("OTP verification failed:", error);
    }
  };
  const handleResendOtp = async () => {
    try {
      const response = await axios.post(
        "https://soiltech.ai/api/login/resend-otp",
        {
          email: email,
        }
      );
      if (response.status === 200) {
        setResendMessage("A new OTP has been sent to your email address.");
      }
    } catch (error) {
      console.error("Failed to resend OTP:", error);
      setResendMessage("Failed to resend OTP. Please try again.");
    }
  };

  return (
    <div className="container">
      <LeftColumn isMobile={isMobile} />

      <div className="right-panel-otp">
        {isMobile ? (
          <div className="signup-container">
            <h2>Welcome to Soil Tech</h2>
            <p>Getting started is easy</p>
            <section className="otp-section">
              <h2>OTP Verification</h2>
              <p>
                Enter the verification code we just sent to your email address:
              </p>
              <div className="otp-inputs">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyUp={(e) => {
                      if (e.target.value && index < otp.length - 1) {
                        // Move to the next input if a digit is entered
                        document
                          .getElementById(`otp-input-${index + 1}`)
                          .focus();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Backspace" &&
                        !e.target.value &&
                        index > 0
                      ) {
                        // Move to the previous input if Backspace is pressed and the current input is empty
                        document
                          .getElementById(`otp-input-${index - 1}`)
                          .focus();
                      }
                    }}
                    id={`otp-input-${index}`} // Give each input an id for easy reference
                  />
                ))}
              </div>
              <button className="submit-btn-otp" onClick={handleSubmitOtp}>
                Submit
              </button>
              <p className="resend-link">
                Didn't receive the code?{" "}
                <a href="#!" onClick={handleResendOtp}>
                  Resend
                </a>
              </p>
              {resendMessage && (
                <p className="resend-message">{resendMessage}</p>
              )}

              {/* <p className="resend-link">
                 Didn't receive the code? <a href="/resend">Resend</a>
                </p> */}
            </section>

            {/* <p>Or continue with</p>
            <div className="social-signin">
              <div className="googlelayout-otp">
                <div className="googlewrap-otp">
                  <img src={googlelogo} alt="Logo" className="googlelogo" />
                  <p className="googletext">Google</p>
                </div>
              </div>
            </div> */}
            <div className="login-prompt-container">
              <p className="login-text">
                Have an account?{" "}
                <a href="login" className="login-link">
                  Log In!
                </a>
              </p>
            </div>
          </div>
        ) : (
          <>
            {/* <Header/> */}

            <div className="signup-header-container">
              <div className="logo-container">
                {/* You can replace this with an actual image tag if needed */}
                <img src={SoilImage} alt="Logo" className="logo" />
              </div>
              <div className="login-prompt-container">
                <p className="login-text">
                  Have an account?{" "}
                  <a href="/" className="login-link">
                    Log In!
                  </a>
                </p>
              </div>
            </div>
            <div className="signup-container">
              <h2>Welcome to Soil Tech</h2>
              <div className="Getting-Started">
                <p>Getting started is easy</p>
                {/* <div className="social-signin">
                  <div className="googlelayout-otp">
                    <div className="googlewrap-otp">
                      <img src={googlelogo} alt="Logo" className="googlelogo" />
                      <p className="googletext">Google</p>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <p>Or continue with</p> */}

              <section className="otp-section">
                <h2>OTP Verification</h2>
                <p>
                  Enter the verification code we just sent to your email
                  address:
                </p>
                <div className="otp-inputs">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyUp={(e) => {
                        if (e.target.value && index < otp.length - 1) {
                          // Move to the next input if a digit is entered
                          document
                            .getElementById(`otp-input-${index + 1}`)
                            .focus();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Backspace" &&
                          !e.target.value &&
                          index > 0
                        ) {
                          // Move to the previous input if Backspace is pressed and the current input is empty
                          document
                            .getElementById(`otp-input-${index - 1}`)
                            .focus();
                        }
                      }}
                      id={`otp-input-${index}`}
                    />
                  ))}
                </div>
                <button className="submit-btn-otp" onClick={handleSubmitOtp}>
                  Submit
                </button>
                <p className="resend-link">
                  Didn't receive the code?{" "}
                  <a href="#!" onClick={handleResendOtp}>
                    Resend
                  </a>
                </p>
                {resendMessage && (
                  <p className="resend-message">{resendMessage}</p>
                )}
              </section>
            </div>
          </>
        )}
      </div>

      <ToastContainer />
    </div>
  );
}

export default OTPPage;
