import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SoilImage from "../../assets/image/SoilTech.svg";
import { toast, ToastContainer } from "react-toastify";

import googlelogo from "../../assets/image/google.svg";
import LeftColumn from "../../components/LeftColumn/LeftColumn";
import bcrypt from "bcryptjs";
import FormInput from "../../components/RegisterComponent/FormInput";
import PasswordValidation from "../../components/RegisterComponent/PasswordValidation";
import TermsAcceptance from "../../components/RegisterComponent/TermsAcceptance";
import SocialSignIn from "../../components/RegisterComponent/SocialSignIn";
import "react-toastify/dist/ReactToastify.css";
import "./HomePage.css";

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    match: false,
  });
  const [showValidationList, setShowValidationList] = useState(true);

  const navigate = useNavigate();
  localStorage.setItem("Email", formData.email);

  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validatePassword = (password, confirmPassword) => {
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      match: password === confirmPassword,
    };
    setPasswordValidation(validations);
    setShowValidationList(!Object.values(validations).every((v) => v));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === "fullname") {
        updatedData.fullname =
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      }

      if (name === "email") {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@(aivot\.ai|aivot\.in)$/;
        setEmailError(
          !emailPattern.test(value)
            ? "Only @aivot.ai and @aivot.in emails are allowed."
            : ""
        );
      }

      if (name === "password" || name === "confirmpassword") {
        validatePassword(updatedData.password, updatedData.confirmpassword);
      }

      return updatedData;
    });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validations = Object.values(passwordValidation);

    if (
      !formData.fullname ||
      !formData.email ||
      !formData.password ||
      !formData.confirmpassword ||
      !isChecked
    ) {
      toast.error(
        "All fields must be filled and Terms of Use must be accepted."
      );
      return;
    }
    if (emailError) {
      toast.error(emailError);
      return;
    }
    if (!validations.every(Boolean)) {
      toast.error("Password does not meet all validation criteria.");
      return;
    }

    try {
      setLoading(true);
      // Encrypt the password
      const salt = bcrypt.genSaltSync(10); // Generate salt
      const hashedPassword = bcrypt.hashSync(formData.password, salt); // Hash the password

      // Update the formData with the hashed password
      const encryptedData = {
        ...formData,
        password: hashedPassword,
        confirmpassword: hashedPassword, // Optional: ensure both match in case backend expects it
      };
      console.log(formData.email);
      const response = await axios.post(
        "https://soiltech.ai/api/login/register",
        encryptedData
      );

      if (response.status === 200) {
        toast.error("Email already Registered");
      } else if (response.status === 201) {
        toast.success("Redirecting to OTP page...");
        navigate("/otp"); // Redirect to OTP page on success
      }
    } catch (error) {
      toast.error("Registration failed. Please try again.");
      console.error("Registration failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container_signup">
      {/* Left Column */}
      <LeftColumn isMobile={isMobile} />
      {/* Right Column */}
      <div className="right-panel-container-signup">
        <div className="header-row-signup">
          <div className="logo-container-signup">
            <img src={SoilImage} alt="Soil Tech Logo" className="soil-logo" />
          </div>
          <div className="login-link-container">
            <span>
              Have an account ?{" "}
              <a href="/signin" className="login-link-signup">
                Log in
              </a>
            </span>
          </div>
        </div>
        <div className="signup-container-form">
          <h2 className="welcome-text-mobile">Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
          {/* <div className="social-signup">
            <SocialSignIn />
          </div>
          <p className="or-continue-with-signup">or continue with</p> */}
          <form onSubmit={handleSubmit}>
            <FormInput
              label="Full Name"
              type="text"
              name="fullname"
              value={formData.fullname}
              onChange={handleInputChange}
              placeholder="Enter Full Name"
            />
            <FormInput
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter Email"
              error={emailError}
            />
            <FormInput
              label="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter Password"
            />
            <FormInput
              label="Confirm Password"
              type="password"
              name="confirmpassword"
              value={formData.confirmpassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
            />
            <PasswordValidation
              passwordValidation={passwordValidation}
              showValidationList={showValidationList}
            />
            <TermsAcceptance
              isChecked={isChecked}
              onChange={handleCheckboxChange}
            />
            <button className="signupbtn" type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Next"}
            </button>
          </form>
          <div className="login-link-container-mobile">
            <span>
              Have an account ?{" "}
              <a href="/" className="login-link-signup">
                Log in
              </a>
            </span>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default HomePage;
