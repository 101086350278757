import { useState, useEffect } from "react";
import axios from "axios";
import defaultProfileImage from "../../assets/image/CompanyLogo.png";

export const useProfileImage = (id) => {
  const [profileImage, setProfileImage] = useState(defaultProfileImage);
  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axios.get(
          "https://soiltech.ai/api/getProfileImage",
          {
            params: {
              id: id,
            },
          }
        );
        // console.log('ye hai filedata',response.data)
        setProfileImage(response.data.imageUrl); // Save the file path to state
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    };

    fetchProfileImage(); // Fetch profile image on component mount
  }, [id]);

  return { profileImage };
};
