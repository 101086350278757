import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import DHeader from "../../components/DashboardHeader/DHeader";
import "./SoilReport.css";
import Funnel from "../../assets/icons/Funnel.svg";
import DropdownBlack from "../../assets/icons/dropdownBlack.svg";
import Download from "../../assets/icons/Download.svg";
import { json, useNavigate } from "react-router-dom";
import axios from "axios";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import Quixote from "../ReportPdf/ReportPdf.jsx";

function SoilReport() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // New state for pagination
  const reportsPerPage = 5; // Reports to display per page
  const userId = localStorage.getItem("GeneratedKey");
  const username = localStorage.getItem("username");
  const farmId = localStorage.getItem("FarmId");
  const [currentId, setCurrentId] = useState(farmId);

  useEffect(() => {
    const Updateid = async () => {
      const farmfetchid = localStorage.getItem("FarmId");
      setCurrentId(farmfetchid);
    };

    window.addEventListener("storageChange", Updateid);

    return () => {
      window.removeEventListener("storageChange", Updateid);
    };
  }, []);

  useEffect(() => {
    const fetchSoilTestDetails = async () => {
      try {
        // Make a POST request to fetch soil test data based on userId and farmId
        const response = await axios.post(
          "https://soiltech.ai/api/selftest/reports",
          {
            userId: userId,
            farmId: currentId,
          }
        );

        // Handle the response - update the state with sorted reports
        setReports(response.data.sortedReports || []);
      } catch (error) {
        setReports([]);
        console.error("Error fetching soil test details:", error);
      }
    };

    // Fetch the data when userId or farmId changes
    if (userId && currentId) {
      fetchSoilTestDetails();
    }
  }, [userId, currentId]);

  const handleRecommendationsClick = (report) => {
    const cropRecommendations = report?.cropVegRecommendation
      ? JSON.parse(report.cropVegRecommendation)
      : [];
    // console.log(cropRecommendations);

    // Construct query parameters with encodeURIComponent to safely include values in the URL
    const queryParams = new URLSearchParams({
      recommendation: cropRecommendations,
      seedingDate: report.seedingDate,
      currentcrop: report.currentCrop,
      city: report.villageCity,
      state: report.state,
    }).toString();
    // Navigate with query parameters
    navigate(`/recommendation?${queryParams}`);
  };
  // console.log(reports)
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile if screen width <= 768px
  };

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize); // Add event listener for resizing
    return () => window.removeEventListener("resize", handleResize); // Clean up listener
  }, []);

  // Pagination logic
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = reports.slice(indexOfFirstReport, indexOfLastReport);
  const nextPage = () => {
    if (currentPage < Math.ceil(reports.length / reportsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  console.log("current reports", currentReports[0]);

  return (
    <div className="Soilreport-Container">
      <div className="Soilreport-content-1">
        <Sidebar />
      </div>
      <div className="Soilreport-content-2">
        <DHeader isMobile={isMobile} />
        <div className="Soilreport-top">
          <div className="Soilreport-heading">Soil Test Reports</div>
          <div className="Soilreport-para">
            Access your soil test reports below to review the results of your
            previous submissions. These reports provide detailed insights into
            your soil's health, nutrient levels, and tailored recommendations
            for improving crop yield.
          </div>
        </div>
        <div className="Soilreport-main-section">
          <div className="Soilreport-main-section-1">
            <table>
              <thead>
                <tr>
                  <div className="Soilreport-TableHeading">
                    <th>Report ID</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Actions</th>
                    <th>Recommendations</th>
                  </div>
                </tr>
              </thead>
              <tbody>
                {currentReports.map((report, index) => (
                  <tr key={index}>
                    <div className="Soilreport-TableContent">
                      <td>{report?._id?.slice(0, 10) + "..."}</td>
                      <td>
                        {
                          new Date(report?.createdAt)
                            .toISOString()
                            .split("T")[0]
                        }
                      </td>
                      <td>
                        {
                          new Date(report?.createdAt)
                            .toTimeString()
                            .split(" ")[0]
                        }
                      </td>
                      <td>
                        <PDFDownloadLink
                          document={
                            <Quixote
                              apiResponse={{
                                ...report.predictions,
                                ...report,
                                username: username,
                              }}
                            />
                          }
                          fileName={`${username}_${report?._id?.slice(0, 10)}_${
                            new Date(report?.createdAt)
                              .toISOString()
                              .split("T")[0]
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          {({ loading }) => (
                            <div className="Soilreport-download">
                              <div className="Soilreport-download-p">
                                {loading ? "Loading" : "Download"}
                              </div>
                              <img
                                src={Download}
                                alt="Download"
                                className="Download"
                              />
                            </div>
                          )}
                        </PDFDownloadLink>
                      </td>
                      <td>
                        <a
                          href="#"
                          style={{ textDecoration: "none", color: "#36518F" }}
                          onClick={() => handleRecommendationsClick(report)}
                        >
                          Recommendations
                        </a>
                      </td>
                    </div>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button
              className="pagination-prev"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>Page {currentPage}</span>
            <button
              className="pagination-next"
              onClick={nextPage}
              disabled={
                currentPage === Math.ceil(reports.length / reportsPerPage)
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoilReport;

{
  /* 
          <div className="Soilreport-icons">
            <div className="Soilreport-funnel">
              <p className="Soilreport-funnel-p">Funnel</p>
              <img src={Funnel} alt="Funnel" className="Funnel" />
            </div>
            <div className="Soilreport-date">
              <p className="Soilreport-date-p">Date</p>
              <img
                src={DropdownBlack}
                alt="Dropdown"
                className="DropdownBlack"
              />
            </div>
            <div className="Soilreport-time">
              <p className="Soilreport-time-p">Time</p>
              <img
                src={DropdownBlack}
                alt="Dropdown"
                className="DropdownBlack"
              />
            </div>
          </div> */
}
