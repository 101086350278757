import React, { useEffect, useState } from "react";
import "./Signin.css";
import axios from "axios";
import backgroundImg from "../../assets/image/farmers-scheme-istock.svg";
import Header from "../../components/Header/Header";
import googlelogo from "../../assets/image/google.svg";
import FormInput from "../../components/RegisterComponent/FormInput";
import SocialSignIn from "../../components/RegisterComponent/SocialSignIn";
import LeftColumn from "../../components/LeftColumn/LeftColumn";
import SoilImage from "../../assets/image/SoilTech.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Authentication/Auth";
import "react-toastify/dist/ReactToastify.css";

function SignIn() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const { storeAuthDataInLS } = useAuth();
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // console.log("data is ", formData);
  const handleRecoverPassword = () => {
    navigate("/forgot-password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!formData.email || !formData.password) {
      toast.error("Please enter both email and password", {
        position: "top-center",
        autoClose: 500,
      });
      return;
    }
    try {
      // Check if the email exists in the database
      const response = await axios.post(
        "https://soiltech.ai/api/login",
        formData
      );
      switch (response.data.status) {
        case -1:
          toast.error("User not registered", {
            position: "top-center",
            autoClose: 500,
          });
          break;
        case 0:
          toast.error("Incorrect password", {
            position: "top-center",
            autoClose: 500,
          });
          break;
        case 1:
          // Email and password matched
          const token = response.data.token;
          const key = response.data.id;
          storeAuthDataInLS(token);
          localStorage.setItem("GeneratedKey", key);
          localStorage.setItem("username", response.data.fullname);
          // localStorage.setItem('email', email);
          navigate("/dashboard", {
            state: { from: "login", loginSuccess: true },
          });
          break;
        default:
          toast.error("An error occurred. Please try again later.", {
            position: "top-center",
            autoClose: 500,
          });
          break;
      }
    } catch (error) {
      console.error("Error checking email:", error);
      toast.error("Internal server error", {
        position: "top-center",
        autoClose: 500,
      });
    }
    //
  };
  return (
    <div className="container">
      {/* Left column: Image and Text */}

      <LeftColumn isMobile={isMobile} />

      {/* Right column: Login Form */}
      <div className="right-column">
        {isMobile ? (
          <div className="form-section">
            <h2 className="welcome-text-mobile">Welcome to Soil Tech</h2>
            <p>Getting started is easy</p>
            <form onSubmit={handleSubmit}>
              <FormInput
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter Email"
                error={emailError}
              />
              <FormInput
                label="Password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter Password"
              />
              {
                <div class="wrapswitch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                  <div class="login-checkboxtext">
                    <span class="login-text1">Remember me</span>
                    <span class="login-text2">
                      <div
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={handleRecoverPassword}
                      >
                        Recover Password
                      </div>
                    </span>
                  </div>
                </div>
              }

              <button type="submit" className="login-button ">
                Log In
              </button>
            </form>
            <p className="login-paragraph">Or sign in with</p>
            {/* <div className="social-signup">
                <SocialSignIn />
              </div> */}
            <div className="login-prompt-container">
              <p className="login-text">
                Don’t you have an account ?{" "}
                <a href="/signup" className="login-link">
                  Sign up!
                </a>
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="login-header-container">
              <div className="logo-container">
                <img src={SoilImage} alt="Logo" className="logo" />
              </div>
              <div className="login-prompt-container">
                <p className="login-text">
                  Don’t have an account?
                  <a href="/signup" className="login-link">
                    Sign up!
                  </a>
                </p>
              </div>
            </div>
            <div className="form-section">
              <h2 className="welcome-text">Welcome to Soil Tech</h2>
              <div className="Getting-Started">
                <p>Getting started is easy</p>
                {/* <div className="social-signup">
                  <SocialSignIn />
                </div> */}
              </div>
              {/* <p>Or continue with</p> */}
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  error={emailError}
                />
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter Password"
                />
                {
                  <div class="wrapswitch">
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                    <div class="login-checkboxtext">
                      <span class="login-text1">Remember me</span>
                      <span class="login-text2">
                        <div
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={handleRecoverPassword}
                        >
                          Recover Password
                        </div>
                      </span>
                    </div>
                  </div>
                }

                <button type="submit" className="login-button ">
                  Log In
                </button>
              </form>
            </div>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default SignIn;
