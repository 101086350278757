import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import backgroundImg from "../../assets/image/farmers-scheme-istock.svg";
import SoilImage from "../../assets/image/SoilTech.svg";
import axios from "axios";
import FormInput from "../../components/RegisterComponent/FormInput";
import { toast, ToastContainer } from "react-toastify";
import LeftColumn from "../../components/LeftColumn/LeftColumn";

function ForgotPassword() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@(aivot\.ai|aivot\.in)$/;
      setEmailError(
        !emailPattern.test(value)
          ? "Only @aivot.ai and @aivot.in emails are allowed."
          : ""
      );
      setEmail(value);
    }
  };
  const handleSendOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://soiltech.ai/api/login/forgot-password",
        { email }
      );
      toast.success(response.data.message);

      navigate("/change-password", { state: { email } });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Error 404 for email not registered
        toast.error(
          "Email not registered. Please check your email or sign up."
        );
      } else if (error.response && error.response.status === 500) {
        toast.error("OTP sending failed. Please try again later.");
      } else {
        // Generic error
        toast.error("Failed to send OTP. Please try again.");
      }
    }
  };
  return (
    <div className="container">
      <LeftColumn isMobile={isMobile} />

      <div className="right-panel-forgot">
        {isMobile ? (
          <div className="signup-container">
            <h2>Welcome to Soil Tech</h2>
            <p>Getting started is easy</p>

            <form className="ForgotPassword-form" onSubmit={handleSendOTP}>
              <p
                style={{ fontWeight: "500", fontSize: "22px", color: "black" }}
              >
                Forgot Password
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  color: "var(--Neutral-700, #4B4B4B)",
                }}
              >
                Enter your email and we’ll send you a link and OTP to reset your
                password
              </p>
              <FormInput
                label="Email"
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
                placeholder="Enter your registered Email"
                error={emailError}
              />

              <button
                type="submit"
                className="sendotp-btn"
                disabled={isLoading}
              >
                {isLoading ? "Sending OTP..." : "SEND OTP"}
              </button>
            </form>

            <div className="forgot-prompt-container">
              <p className="login-text">
                Don’t have an account?{" "}
                <button className="login-link" onClick={handleSignUpClick}>
                  Sign up!
                </button>
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="forgot-header-container">
              <div className="logo-container">
                <img src={SoilImage} alt="Logo" className="logo" />
              </div>
              <div className="forgot-prompt-container">
                <p className="login-text">
                  Don’t have an account?{" "}
                  <button className="login-link" onClick={handleSignUpClick}>
                    Sign up!
                  </button>
                </p>
              </div>
            </div>
            <div className="signup-container">
              <h2>Welcome to Soil Tech</h2>
              <p>Getting started is easy</p>

              <form className="ForgotPassword-form" onSubmit={handleSendOTP}>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "22px",
                    color: "black",
                  }}
                >
                  Forgot Password
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "var(--Neutral-700, #4B4B4B)",
                  }}
                >
                  Enter your email and we’ll send you a link and OTP to reset
                  your password
                </p>
                <FormInput
                  label=""
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Enter your registered Email"
                  error={emailError}
                />

                <button
                  type="submit"
                  className="sendotp-btn"
                  disabled={isLoading}
                >
                  {isLoading ? "Sending OTP..." : "SUBMIT"}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
