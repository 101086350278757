import React, { useState, useEffect } from "react";
import green_plant from "../../assets/images/green_plant_cropped.png";
import red_plant from "../../assets/images/red_plant_cropped.png";
import orange_plant from "../../assets/images/orange_plant_cropped.png";
import yellow_plant from "../../assets/images/yellow_plant_cropped.png";
import soiltech from "../../assets/image/soiltech.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./SoilTestReport.css";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import Quixote from "./ReportPdf.jsx";

const SoilTestReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formData } = location.state || {}; // Use formData from state
  console.log("formdata", formData);
  console.log(formData.predictions["pH"]);

  const imageArray = [red_plant, orange_plant, yellow_plant, green_plant];
  const colors = ["red", "orange", "yellow", "green"];
  const username = localStorage.getItem("username");
  const descriptionArray = [
    "High, Acid, Saline, Highly Alkaline, Low (OC, OM)",
    "Low Deficient",
    "Medium (OC, OM, mbc)",
    "Medium, Neutral, Sufficient, Alkaline\nSlightly Acidic, High (OC, OM, mbc)",
  ];

  const keysArray = [
    ["pH", "pH"],
    ["EC", "EC"],
    ["Organic Carbon", "OrganicCarbon"],
    ["Organic Matter", "OrganicMatter"],
    ["Available Nitrogen", "Nitrogen"],
    ["Available Phosphorus", "Phosphorus"],
    ["Available Potassium", "K_Potassium"],
    ["Available Sulphur", "Sulphur"],
    ["Available Copper", "Cu"],
    ["Available Iron", "Fe"],
    ["Available Zinc", "Zn"],
    ["Available Boron", "Boron"],
    ["Soil Health Index (SHI)", "SHI"],
    ["Bulk Density", "bulkdensity"],
  ];

  const ranges = {
    Boron: [0.5, Infinity],
    Cu: [0.2, Infinity],
    EC: [0, 1],
    Fe: [2.5, Infinity],
    K_Potassium: [110, 280],
    Mn: [0, 1],
    Nitrogen: [280, 560],
    OrganicCarbon: [0.5, 0.75],
    OrganicMatter: [0.86, 1.29],
    Phosphorus: [10, 25],
    Sulphur: [10, 30],
    Zn: [0.6, Infinity],
    pH: [6.5, 7.5],
    SHI: [0, 1],
    bulkdensity: [1, 5],
  };

  const units = [
    "mg/kg", // Boron
    "mg/kg", // Cu
    "dS/m", // EC
    "mg/kg", // Fe
    "kg/ha", // K_Potassium
    "mg/kg", // Mn
    "kg/ha", // Nitrogen
    "w%", // OrganicCarbon
    "w%", // OrganicMatter
    "kg/ha", // Phosphorus
    "mg/kg", // Sulphur
    "mg/kg", // Zn
    "", // pH
    "", //SHI
    "", //Bulkdensity
  ];

  const classify = (value, range) => {
    if (!range) return 3;
    if (value < range[0]) return 1; // Low Deficient
    if (value > range[1]) return 0; // High
    return 3; // Medium or Neutral
  };

  const rangeStr = (range) => {
    let res = "range ";
    res += range[0] + " ";
    if (range[1] === Infinity) res += "<";
    else res += "- " + range[1];
    return res;
  };

  const cropGrowingMonthsMap = {
    wheat: { growingMonths: "November-March", season: "Rabi" },
    barley: { growingMonths: "November-March", season: "Rabi" },
    mustard: { growingMonths: "October-January", season: "Rabi" },
    peas: { growingMonths: "October-February", season: "Rabi" },
    gram: { growingMonths: "October-January", season: "Rabi" },
    lentils: { growingMonths: "October-January", season: "Rabi" },
    rice: { growingMonths: "June-October", season: "Kharif" },
    maize: { growingMonths: "June-October", season: "Kharif" },
    cotton: { growingMonths: "April-August", season: "Kharif" },
    sorghum: { growingMonths: "July-October", season: "Kharif" },
    bajra: { growingMonths: "June-September", season: "Kharif" },
    pea: { growingMonths: "June-October", season: "Kharif" },
    groundnut: { growingMonths: "June-September", season: "Kharif" },
    soybean: { growingMonths: "June-September", season: "Kharif" },
    watermelon: { growingMonths: "February-May", season: "Zaid" },
    cucumber: { growingMonths: "February-May", season: "Zaid" },
    muskmelon: { growingMonths: "February-May", season: "Zaid" },
    bittergourd: { growingMonths: "March-June", season: "Zaid" },
    pumpkin: { growingMonths: "February-May", season: "Zaid" },
    summermaize: { growingMonths: "March-June", season: "Zaid" },
    cowpea: { growingMonths: "April-July", season: "Zaid" },
    tea: { growingMonths: "Throughout the year", season: "Perennial" },
    coffee: { growingMonths: "Throughout the year", season: "Perennial" },
    rubber: { growingMonths: "Throughout the year", season: "Perennial" },
    tobacco: { growingMonths: "October-January", season: "Rabi" },
    sugarcane: { growingMonths: "October-February", season: "Perennial" },
    coconut: { growingMonths: "Throughout the year", season: "Perennial" },
    tomato: {
      growingMonths: "January-April, September-October",
      season: "Zaid",
    },
    potato: { growingMonths: "October-January", season: "Rabi" },
    onion: { growingMonths: "October-February", season: "Rabi" },
    brinjal: { growingMonths: "January-July", season: "Zaid" },
    cabbage: { growingMonths: "October-February", season: "Rabi" },
    cauliflower: { growingMonths: "October-January", season: "Rabi" },
    carrot: { growingMonths: "October-January", season: "Rabi" },
    ladyfinger: { growingMonths: "April-August", season: "Kharif" },
    chili: { growingMonths: "January-May", season: "Zaid" },
    spinach: { growingMonths: "September-January", season: "Rabi" },
    radish: { growingMonths: "September-January", season: "Rabi" },
    bottlegourd: { growingMonths: "February-May", season: "Zaid" },
    mushroom: { growingMonths: "Throughout the year", season: "Perennial" },
    coriander: { growingMonths: "October-February", season: "Rabi" },
    jute: { growingMonths: "March-July", season: "Kharif" },
    "black Gram": {
      growingMonths: "June-October, October-March",
      season: "Kharif, Rabi",
    },
    ginger: { growingMonths: "March-August", season: "Kharif" },
    "Black Pepper": { growingMonths: "June-February", season: "Perennial" },
  };

  const [userData, setUserData] = useState(null);
  const [farmData, setFarmData] = useState(null);
  const userId = localStorage.getItem("GeneratedKey");
  const farmId = localStorage.getItem("FarmId");
  console.log("farm identification", farmId);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://soiltech.ai/api/login/?${new URLSearchParams({
            id: userId,
          })}`
        );
        setUserData(response.data.LoginData);
        console.log(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const fetchFarmData = async () => {
      try {
        const response = await axios.post(
          "https://soiltech.ai/api/farmData/farmdetails",
          {
            Id: userId,
            farmId: farmId,
          }
        );
        setFarmData(response.data.farmData);
        // console.log(response.data.farmData);
      } catch (error) {
        console.error("Error fetching farm data:", error);
      }
    };
    fetchFarmData();
  }, [userId, farmId]);

  const date = new Date(formData?.createdAt || Date.now());
  const dateStr = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const handleDashboard = () => {
    navigate("/dashboard");
  };
  const handleDownload = () => {
    navigate("/soilreport");
  };
  const cropRecommendations = formData?.cropVegRecommendation
    ? JSON.parse(formData.cropVegRecommendation)
    : [];

  return (
    <div className="report-page">
      <header className="report-header">
        <img src={soiltech} alt="Soiltech Logo" className="pdf-logo" />
        <h1>SOIL TEST REPORT</h1>
      </header>

      <div className="details-container">
        <div className="farmer-details">
          <div className="farmer-details-block">
            <p style={{ color: "white" }}>
              <strong>Farmer Details</strong>
            </p>
            <p style={{ color: "white" }}>
              <strong>Name: </strong>
              {userData?.Fullname}
            </p>
            <p style={{ color: "white" }}>
              <strong>Address: </strong> {formData?.location}
            </p>
            <p style={{ color: "white" }}>
              <strong>Phone: </strong> {userData?.phonenumber}
            </p>
          </div>
        </div>
        <div className="sample-info">
          <div className="Sample-Information-block">
            <p style={{ color: "white" }}>
              <strong>Sample Information</strong>
            </p>
            <p style={{ color: "white" }}>
              <strong>Agriculture Land Size:</strong> {farmData?.AreaofLand}
            </p>
            <p style={{ color: "white" }}>
              <strong>Location:</strong> {formData?.villageCity}
            </p>
            <p style={{ color: "white" }}>
              <strong>Survey No:</strong> {formData?.surveyNumber}
            </p>
            <p style={{ color: "white" }}>
              <strong>Test Date:</strong> {dateStr}
            </p>
          </div>
        </div>
      </div>

      <div className="Report-table">
        {Array.from({ length: 5 }).map((_, i) => (
          <div key={i} className="Report-table-row">
            {Array.from({ length: 3 }).map((_, j) => {
              const k = i * 3 + j;
              if (k > 13) return null; // 14 fields, stop if index exceeds
              const keyName = keysArray[k][1];
              const classification = classify(
                formData.predictions[keyName],
                ranges[keyName]
              );

              return (
                <div key={k} className="Report-table-col">
                  <div className="Report-image-container">
                    <img
                      src={imageArray[classification]}
                      alt="classification"
                      className="Report-table-image"
                    />
                  </div>
                  <div className="Report-details-container">
                    <p className="table-cell">
                      {keysArray[k][0]}{" "}
                      <span style={{ color: colors[classification] }}>
                        {formData.predictions[keyName]}
                      </span>{" "}
                      {units[k]}
                    </p>
                    <p className="table-cell">{rangeStr(ranges[keyName])}</p>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <div className="Report-Recommendation">
        <h2>Recommendation</h2>
        <p>
          <strong>Soil Conditioner / Amendment</strong>
        </p>

        {/* Add any other details you need here */}

        <div className="table-container">
          <table className="crop-table">
            <thead>
              <tr>
                <th>Crop Name</th>
                <th>Urea (kg/ha)</th>
                <th>Potash (kg/ha)</th>
                <th>DAP (kg/ha)</th>
                <th>Season</th>
                <th>Recommended Month</th>
              </tr>
            </thead>
            <tbody>
              {cropRecommendations.map((crop, index) => (
                <tr key={index}>
                  <td>{crop[0]}</td>
                  <td>{crop[1]}</td>
                  <td>{crop[2]}</td>
                  <td>{crop[3]}</td>
                  <td>{cropGrowingMonthsMap[crop[0].toLowerCase()]?.season}</td>
                  <td>
                    {cropGrowingMonthsMap[crop[0].toLowerCase()]?.growingMonths}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="Report-photo-section">
        <h2>Scaling</h2>
        <div className="Report-photo-container">
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="Report-photo-item">
              <img
                src={imageArray[index]}
                alt="description"
                className="Report-desc-image"
              />
              <p
                style={{
                  fontSize: "10px",
                  color: colors[index],
                  fontStyle: "italic",
                }}
              >
                {descriptionArray[index]}
              </p>
            </div>
          ))}
        </div>
      </div>

      <footer className="report-footer">
        <p style={{ color: "red", fontStyle: "italic" }}>
          NOTE: The fertilizer recommendation shown in this report is for a full
          season of the selected crop.
        </p>
      </footer>

      <div className="TestReport-Btn-Div">
        {localStorage.getItem("token") && (
          <button className="Report-Retest-Btn" onClick={handleDashboard}>
            Dashboard
          </button>
        )}
        <PDFDownloadLink
          document={
            <Quixote
              apiResponse={{
                ...formData.predictions,
                ...formData,
                userData,
                farmData,
                userId,
              }}
            />
          }
          fileName={`${username}_${new Date().toISOString().split("T")[0]}`}
          style={{ textDecoration: "none" }}
        >
          <div className="Report-Download-Btn"> Download Report</div>
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default SoilTestReport;
