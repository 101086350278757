import { useState, useEffect } from "react";
import axios from "axios";

export const useSoilData = (userId, farmId) => {
  const [soilData, setSoilData] = useState([]);
  const [soilshi, setSoilshi] = useState();
  const [insightsData, setInsightsData] = useState([]);
  const [error, setError] = useState(null);
  const [currentcrop, setCurrentcrop] = useState("");
  const [seedingdate, setSeedingDate] = useState("");
  const [season_curr, setSeason_curr] = useState("");

  useEffect(() => {
    fetchSoilData();
  }, [userId, farmId]);

  const fetchSoilData = async () => {
    try {
      const response = await axios.post(
        "https://soiltech.ai/api/selftest/fetchdata",
        { userId: userId, farmId: farmId }
      );
      const { currentCrop, season, seedingDate, predictions, SHI, Insights } =
        response.data;
      setSoilData(transformSoilData(predictions));
      setInsightsData(formatInsights(Insights));
      setSoilshi(SHI);
      setCurrentcrop(currentCrop);
      setSeedingDate(seedingDate);
      setSeason_curr(season);
    } catch (err) {
      setSoilData("");
      setInsightsData("");
      setSoilshi("");
      setCurrentcrop("");
      setSeedingDate("");
      setSeason_curr("");
      setError("Error fetching soil data.");
    }
  };
  return {
    currentcrop,
    season_curr,
    seedingdate,
    soilData,
    insightsData,
    soilshi,
    error,
  };
};

const standardLabels = {
  Boron: "B",
  Cu: "Cu",
  EC: "EC",
  Fe: "Fe",
  K_Potassium: "K",
  Mn: "Mn",
  Nitrogen: "N",
  OrganicCarbon: "OC",
  OrganicMatter: "OM",
  Phosphorus: "P",
  Sulphur: "S",
  Zn: "Zn",
  pH: "pH",
};

const colors = {
  pH: "#FFC107",
  Zn: "#FFC107",
  Mn: "#FFC107",
  Cu: "#FFC107",
  Nitrogen: "#28A745",
  OrganicCarbon: "#28A745",
  OrganicMatter: "#28A745",
  Boron: "#007BFF",
  EC: "#007BFF",
  Fe: "#007BFF",
  K_Potassium: "#007BFF",
  Phosphorus: "#007BFF",
  Sulphur: "#007BFF",
};

const transformSoilData = (predictions) => {
  if (!predictions || typeof predictions !== "object") {
    return []; // Return an empty array if predictions is null or not an object
  }
  // console.log(predictions)
  return Object.keys(predictions)
    .map((key) => {
      if (key === "createdAt") return null; // Skip timestamp

      return {
        label: standardLabels[key] || key, // Use standard symbol or original key
        value: predictions[key],
        secondaryValue: null, // Assign secondary value if needed
        color: colors[key] || "#6c757d", // Default color if not defined
      };
    })
    .filter((item) => item !== null); // Remove any null items
};

const formatInsights = (insights) => {
  if (!insights || typeof insights !== "string") {
    return []; // Return an empty array if insights is null or not a string
  }

  // Split by period followed by space or end of string to get potential sentences
  const rawSentences = insights.split(/\. +|\.$/);

  // Filter out any empty lines or lines that are not actual sentences
  const formattedSentences = rawSentences
    .map((sentence) => sentence.trim()) // Trim whitespace
    .filter((sentence) => sentence.length > 0); // Remove empty strings

  return formattedSentences;
};
